<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
      <!-- header -->
      <div class="flex-col section_2">
        <div class="justify-between group_2">
          <span class="text_1">{{title}}</span>
          <div class="flex-row group_3">
            <i class="image_2"></i>
            <span class="text_2">{{status}}</span>
          </div>
        </div>
        <div class="flex-row group_4">
          <div class="flex-col group_5">
            <span class="text_3">{{add}}</span>
            <div class="flex-row group_6">
              <img src="../../../assets/icon18.png" class="image_3" />
              <span class="text_4">设备代码：{{number}}</span>
            </div>
            <div class="flex-row group_7">
              <img src="../../../assets/icon19.png" class="image_4" />
              <span class="text_5">所在小区:{{addItem}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col line-tabs">
        <!-- 主导航 -->
        <div class="justify-center list">
          <span @click="tab(i)" :class="['list-item',{active:i == curId}]" :key="i" v-for="(navItem, i) in Dnav">{{navItem.navItem}}</span>
        </div>
      </div>
      <div class="flex-row list_1" v-if="scc">
            <div
              @click="tab1(i)"
              :class="['list-item_1',{'list-item_10':i==curId1},'flex-col','items-center']"
              :key="i"
              v-for="(item, i) in nav"
            >
              <span>{{item.navItem}}</span>
            </div>
      </div>
    </div>
    <div style="height:2.3rem" v-if="scc"></div>
    <!-- 下面是灵活多态的详细数据 -->
    <router-view/>
  </div>
</template>

<script>
import {info,detailed} from '@/api/Maintenance/Elevator/all'
export default {
  data() {
    return {
      nav: [
        { navItem: '基本信息' },
        { navItem: '登记信息' },
        { navItem: '维保记录' },
        { navItem: '维修记录' },
        { navItem: '年检记录' },
        { navItem: '救援记录' }
      ],
      Dnav: [{ navItem: '电梯档案' }, { navItem: '实时状态' }, { navItem: '部件管理' }, { navItem: '视频监控' }],
      title: '',
      status: '',
      add: '',
      number: '',
      addItem: '',
      curId: 0,
      curId1: 0,
      scc:true
    }
  },
  methods: {
    tab(index) {
      this.curId = index
      if(index===1||index===2||index===3){
        this.scc=false
      }else if(index===0){
        this.scc=true
      }
      if(index===0){
        this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/basic-info') //基本信息
      }else if(index===1){
        this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/running-status') //实时运行状态
      }else if(index===2){
        this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/parts')  //零部件管理
      }else if(index===3){
        this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/monitor') //视频监控
      }
    },
    tab1(index) {
      if(index!==this.curId1){
        if(index===0){
          this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/basic-info') //基本信息
        }else if(index===1){
          this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/register-info') //登记信息
        }else if(index===2){
          this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/maintenance')//维保记录
        }else if(index===3){
          this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/alarm') //维修记录
        }else if(index===4){
          this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/annual-inspection')//年检记录
        }else if(index===5){
          this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/rescue')//救援记录
        }
      }
      this.curId1 = index
    },
  },
  mounted(){
    if(this.curId1===0){
      this.$router.push('/maintenance/elevator/'+this.$route.params.id+'/basic-info')
    }
    detailed({id:this.$route.params.id}).then(res=>{
      // console.log(res);
      this.title=res.data.Data.Number
      this.number=res.data.Data.RegisterNumber
      this.addItem=res.data.Data.HousesName
      // "status":状态
      // 0 => '正常'1 => '故障'，2主动关停
      if(Number(res.data.Data.status)===0){
        this.status='正常'
      }else if(Number(res.data.Data.status)===1){
        this.status='故障'
      }else if(Number(res.data.Data.status)===2){
        this.status='主动关停'
      }
    })
    info({id:this.$route.params.id}).then(res=>{
      console.log(res);
      this.add=res.data.Data.address
    })
  }
}
</script>

<style scoped>
@import '../../../assets/common.css';
.list-item {
  color: rgb(180, 180, 180);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  padding-top: 0.38rem;
}
.active {
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  line-height: 1.19rem;
  white-space: nowrap;
  padding-top: 0;
}
.list-item_1 {
  padding: 0.54rem 0;
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  border: 1px solid #f0f2f0;
  border-radius: 1rem;
  width: 4.5rem;
  height: 2rem;
}
.list-item_1:not(:first-of-type) {
  margin-left: 0.5rem;
}
.list-item_10 {
  padding: 0.54rem 0;
  color: rgb(30, 200, 64);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  border: 1px solid #dffae5;
  background: #dffae5;
  border-radius: 1rem;
  width: 4.5rem;
  height: 2rem;
}
.list-item_2 {
  padding: 1.19rem 0.063rem;
}
.text_15 {
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  display: inline-block;
  width: 6rem;
}
.text_17 {
  margin-left: 1.13rem;
  margin-top: 0.13rem;
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.63rem;
  white-space: nowrap;
}
.page {
  padding-top: 0.063rem;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.94rem 0.81rem 0.88rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  overflow-y: auto;
  position: relative;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.section_2 {
  padding: 1.75rem 0 3.75rem 1.38rem;
  background: url(../../../assets/imgbg3.png) center top no-repeat;
  background-size: 100% 100%;
}
.line-tabs {
  overflow-x: hidden;
  border-radius: 1rem;
  background: #fff;
  margin-top: -2.4rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.75rem;
}
.group_2 {
  padding: 0 0.13rem;
}
.group_4 {
  margin-top: 1rem;
}
.list {
  padding: 1.88rem 0;
}
.group_9 {
  padding: 0 1.25rem 0 1.25rem;
  padding-bottom: 5.25rem;
  overflow-x: hidden;
}
.text_1 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
}
.group_3 {
  margin-right: 0.44rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_5 {
  flex: 1 1 auto;
}
.list-item:not(:first-of-type) {
  margin-left: 1.25rem;
}
.list_1 {
  overflow-x: scroll;
  padding-bottom: 0.8rem;
  margin: 0 1rem 0 1rem;
}
.list_2 {
  margin-top: 0.63rem;
  height: 47.81rem;
  position: relative;
}
.image_2 {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: #4cd964;
  border: 1px solid #fff;
  margin-top: 0.1rem;
}
.text_2 {
  margin-left: 0.31rem;
}
.text_3 {
  margin-left: 0.19rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_6 {
  margin-top: 1rem;
  padding: 0 0.063rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.group_7 {
  margin-top: 0.81rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.image_3 {
  width: 0.75rem;
  height: 0.94rem;
}
.text_4 {
  margin-left: 0.31rem;
  margin-top: 0.13rem;
}
.image_4 {
  width: 0.81rem;
  height: 0.88rem;
}
.text_5 {
  margin-left: 0.31rem;
}
.flex-col.section_2{
  overflow: hidden;
}
</style>

