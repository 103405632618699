/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-01-19 14:48:23
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-04-27 10:07:29
 */
import request from '@/request/request'

export function list(data) {
    return request({
        url: '/Maintenance/Elevator/List',
        method: 'post',
        data
    })
}

// 详细数据
export function detailed(data) {
    return request({
        url:'/Maintenance/Elevator/Info',
        method:'post',
        data
    })
}
// 登记信息
export function info(data) {
    return request({
        url:'/Maintenance/Elevator/InfoRegister',
        method:'post',
        data
    })
}
// 维保记录
export function InfoMaintenanceList(data) {
    return request({
        url:'/Maintenance/Elevator/InfoMaintenanceList',
        method:'post',
        data
    })
}
// 维修记录
export function InfoAlarmList(data) {
    return request({
        url:'/Maintenance/Elevator/InfoAlarmList',
        method:'post',
        data
    })
}
// 年检记录
export function InfoAnnualInspectionList(data) {
    return request({
        url:'/Maintenance/Elevator/InfoAnnualInspectionList',
        method:'post',
        data
    })
}
// 救援记录
export function InfoRescueList(data) {
    return request({
        url:'/Maintenance/Elevator/InfoRescueList',
        method:'post',
        data
    })
}
// 实时状态
export function real(data) {
    return request({
        url:'/Maintenance/Elevator/RunningStatus',
        method:'post',
        data
    })
}
// 直播流
export function Live(data) {
    return request({
        url:'/Maintenance/Elevator/LiveVideo',
        method:'post',
        data
    })
}


